import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

const MATTILSYNET_SPECIES = [
    { id: 0, dyreIdId: 1, name: 'Ukjent' },
    { id: 1212, dyreIdId: 1, name: 'Ukjent' },
    { id: 1, dyreIdId: 1, name: 'NRF' },
    { id: 2, dyreIdId: 1, name: 'Jersey' },
    { id: 3, dyreIdId: 1, name: 'Sidet trønderfe og nordlandsfe' },
    { id: 4, dyreIdId: 1, name: 'Telemarkfe' },
    { id: 5, dyreIdId: 1, name: 'Dølafe' },
    { id: 6, dyreIdId: 1, name: 'Østlandsk rødkolle' },
    { id: 7, dyreIdId: 1, name: 'Vestlandsk raudkolle' },
    { id: 8, dyreIdId: 1, name: 'Vestlandsk fjordfe' },
    { id: 9, dyreIdId: 1, name: 'Holstein' },
    { id: 10, dyreIdId: 1, name: 'RDM' },
    { id: 11, dyreIdId: 1, name: 'Brown Swiss' },
    { id: 12, dyreIdId: 1, name: 'Jarlsbergsfe' },
    { id: 13, dyreIdId: 1, name: 'Flechvieh' },
    { id: 14, dyreIdId: 1, name: 'Canadisk Ayrshire' },
    { id: 15, dyreIdId: 1, name: 'Montbéliarde' },
    { id: 17, dyreIdId: 1, name: 'Normande' },
    { id: 21, dyreIdId: 1, name: 'Hereford' },
    { id: 22, dyreIdId: 1, name: 'Charolais' },
    { id: 23, dyreIdId: 1, name: 'Aberdeen angus' },
    { id: 24, dyreIdId: 1, name: 'Limousin' },
    { id: 25, dyreIdId: 1, name: 'Kjøttsimmental' },
    { id: 26, dyreIdId: 1, name: "Blonde d'aquitaine" },
    { id: 27, dyreIdId: 1, name: 'Highland' },
    { id: 28, dyreIdId: 1, name: 'Tiroler grauvieh' },
    { id: 29, dyreIdId: 1, name: 'Dexter' },
    { id: 30, dyreIdId: 1, name: 'Piemontese' },
    { id: 31, dyreIdId: 1, name: 'Galloway' },
    { id: 32, dyreIdId: 1, name: 'Salers' },
    { id: 33, dyreIdId: 1, name: 'Chianina' },
    { id: 35, dyreIdId: 1, name: 'Mini Hereford' },
    { id: 36, dyreIdId: 1, name: 'Lakenvelder' },
    { id: 37, dyreIdId: 1, name: 'Svart Limousine' },
    { id: 39, dyreIdId: 1, name: 'Wagyu' },
    { id: 40, dyreIdId: 1, name: 'Jak' },
    { id: 41, dyreIdId: 1, name: 'Lowline Angus' },
    { id: 42, dyreIdId: 1, name: 'Pinzgauer' },
    { id: 43, dyreIdId: 1, name: 'Aubrac' },
    { id: 44, dyreIdId: 1, name: 'Stabiliser' },
    { id: 45, dyreIdId: 1, name: 'Speckle Park' },
    { id: 49, dyreIdId: 1, name: 'Ukjent' },
    { id: 98, dyreIdId: 1, name: 'Krysning' },
    { id: 99, dyreIdId: 1, name: 'Ukjent' },
];

const MATTILSYNET_BREEDS = [
    { id: 1212, dyreIdBreedId: 0, name: 'Ukjent' },
    { id: 99, dyreIdBreedId: 0, name: 'Ukjent' },
    { id: 49, dyreIdBreedId: 0, name: 'Ukjent' },
    { id: 9, dyreIdBreedId: 0, name: 'RDM' },
    { id: 13, dyreIdBreedId: 0, name: 'Flechvieh' },
    { id: 15, dyreIdBreedId: 0, name: 'Montbéliarde' },
    { id: 25, dyreIdBreedId: 0, name: 'Kjøttsimmental' },
    { id: 30, dyreIdBreedId: 0, name: 'Piemontese' },
    { id: 32, dyreIdBreedId: 0, name: 'Salers' },
    { id: 36, dyreIdBreedId: 0, name: 'Lakenvelder' },
    { id: 37, dyreIdBreedId: 0, name: 'Svart Limousine' },
    { id: 41, dyreIdBreedId: 0, name: 'Lowline Angus' },
    { id: 42, dyreIdBreedId: 0, name: 'Pinzgauer' },
    { id: 43, dyreIdBreedId: 0, name: 'Aubrac' },
    { id: 44, dyreIdBreedId: 0, name: 'Stabiliser' },
    { id: 45, dyreIdBreedId: 0, name: 'Speckle Park' },

    { id: 21, dyreIdBreedId: 111, name: "Hereford" },
    { id: 23, dyreIdBreedId: 112, name: "Aberdeen Angus" },
    // { id: , dyreIdBreedId: 183, name: "Blanding storfe" },
    { id: 22, dyreIdBreedId: 221, name: "Charolais" },
    // { id: , dyreIdBreedId: 237, name: "Simmental" },
    { id: 27, dyreIdBreedId: 352, name: "Highland" },
    { id: 2, dyreIdBreedId: 403, name: "Jersey" },
    { id: 24, dyreIdBreedId: 446, name: "Limousin" },
    { id: 1, dyreIdBreedId: 485, name: "NRF" },
    // { id: , dyreIdBreedId: 649, name: "Ukjent" },
    // { id: , dyreIdBreedId: 751, name: "Annet" },
    { id: 11, dyreIdBreedId: 752, name: "Brown Swiss" },
    { id: 14, dyreIdBreedId: 753, name: "Canadisk Ayrshire" },
    { id: 33, dyreIdBreedId: 754, name: "Chianina" },
    { id: 29, dyreIdBreedId: 755, name: "Dexter" },
    { id: 5, dyreIdBreedId: 756, name: "Dølafe" },
    { id: 31, dyreIdBreedId: 757, name: "Galloway" },
    { id: 9, dyreIdBreedId: 758, name: "Holtstein" },
    { id: 40, dyreIdBreedId: 759, name: "Jak (Bos Grunniens)" },
    { id: 12, dyreIdBreedId: 760, name: "Jarlsbergfe" },
    { id: 35, dyreIdBreedId: 761, name: "Mini Hereford" },
    // { id: , dyreIdBreedId: 762, name: "Montbeliarde" },
    // { id: , dyreIdBreedId: 763, name: "Mørafe" },
    { id: 17, dyreIdBreedId: 764, name: "Normande" },
    // { id: , dyreIdBreedId: 765, name: "Piedmontese" },
    // { id: , dyreIdBreedId: 766, name: "Rød Dansk Melkerace" },
    // { id: , dyreIdBreedId: 767, name: "Sallers" },
    { id: 3, dyreIdBreedId: 768, name: "Sidet Trønder- og Nordlandsfe" },
    { id: 4, dyreIdBreedId: 769, name: "Telemarksfe" },
    { id: 28, dyreIdBreedId: 770, name: "Tiroler Grauvieh" },
    { id: 8, dyreIdBreedId: 771, name: "Vestlandsk Fjordfe" },
    { id: 7, dyreIdBreedId: 772, name: "Vestlandsk Raukolle" },
    { id: 39, dyreIdBreedId: 773, name: "Wagyu" },
    { id: 6, dyreIdBreedId: 774, name: "Østlandsk Rødkolle" },
    { id: 26, dyreIdBreedId: 775, name: "Blonde d'Aguitaine" },
    // { id: , dyreIdBreedId: 808, name: "NORSK RØDT FE" },
];

const MATTILSYNET_GENDER = [
    { id: 1, gender: 1, name: 'Okse' },
    { id: 2, gender: 2, name: 'Ku/kvige' },
];

class AnimalStore extends LocalModel {
    constructor() {
        super('animal', {
            namePlural: 'animals',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/animals/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/animals/',
                    params: {},
                },
                save: {
                    url: '/api/animals/',
                    params: {},
                },
                delete: {
                    url: '/api/animals/',
                    params: {},
                },
            },
        });
    }

    @observable newAnimal = {};

    @observable animal = {};

    @observable animals = [];

    @observable owners = [];

    @observable species = [];

    @observable breeds = [];

    @observable currentAnimals = [];

    @observable dyreIdApproved = false;

    getMatilsynetSpecies(id, field) {
        const idInt = parseInt(id, 10);
        const species = MATTILSYNET_SPECIES.find((s) => s.id === idInt);
        if (field && species && species[field]) {
            return species[field];
        }
        return species?.dyreIdId;
    }

    getMatilsynetBreed(id, field) {
        // MATTILSYNET_BREEDS = [
            // { id: 1212, dyreIdBreed: 0, name: 'Ukjent' },
        const idInt = parseInt(id, 10);
        const breed = MATTILSYNET_BREEDS.find((s) => s.id === idInt);
        if (field && breed && breed[field]) {
            return breed[field];
        }
        return breed?.dyreIdBreedId;
    }

    getMatilsynetGender(id, field) {
        const idInt = parseInt(id, 10);
        const gender =  MATTILSYNET_GENDER.find((s) => s.id === idInt);
        if (field && gender && gender[field]) {
            return gender[field];
        }
        return gender?.gender;
    }

    @action
    setDyreIdApproved = (dyreIdApproved) => {
        this.dyreIdApproved = dyreIdApproved;
    }

    async loadSpecies() {
        this.updateKeyValue('species', []);
        const response = await util.fetchApi(`/api/animalspecies/`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateKeyValue('species', response.data);
                return response;
        }
    }

    async loadBreeds(speciesId) {
        this.updateKeyValue('breeds', []);
        const response = await util.fetchApi(`/api/animalbreeds/`, { method: 'GET' }, {
            speciesId
        });
        switch (response.status) {
            case 200:
                this.updateKeyValue('breeds', response.data);
                return response;
        }
    }

    async dyreIdGetChipInfo(chipId) {
        const response = await util.fetchApi(`/api/dyreid/${chipId}`, { method: 'GET' }, {
        });
        switch (response?.status) {
            case 200:
                // this.updateKeyValue('breeds', response.data);
                return response;
        }
    }

    async dyreIdGetSpeciesAndBreed() {
        const response = await util.fetchApi(`/api/dyreid/speciesandbreed`, { method: 'GET' }, {
        });
        switch (response?.status) {
            case 200:
                // this.updateKeyValue('breeds', response.data);
                return response;
        }
    }

    async dyreIdSearchOwnerPet(cellphone) {
        const response = await util.fetchApi(`/api/dyreid/searchownerpet/${cellphone}`, { method: 'GET' }, {
        });
        switch (response?.status) {
            case 200:
                // this.updateKeyValue('breeds', response.data);
                return response;
        }
    }

    async dyreIdGetOwnerPetsInfoByPhoneNumberEmail(cellphone) {
        const response = await util.fetchApi(`/api/dyreid/getownerpetsinfobyphonenumberemail/${cellphone}`, { method: 'GET' }, {
        });
        switch (response?.status) {
            case 200:
                // this.updateKeyValue('breeds', response.data);
                return response;
        }
    }

    async dyreIdRegisterPetOwnerInfoV1(animal) {
        const response = await util.fetchApi(`/api/dyreid/registerpetownerinfov1/${animal}`, { method: 'GET' }, {
        });
        switch (response?.status) {
            case 200:
                // this.updateKeyValue('breeds', response.data);
                this.setFeedback('chipId', 'ChipId is now registered with DyreID.');
                return response;
        }
    }

    async dyreIdChipValidationStatus(chipId) {
        const response = await util.fetchApi(`/api/dyreid/chipvalidationstatus/${chipId}`, { method: 'GET' }, {
        });
        switch (response?.status) {
            case 200:
                // this.updateKeyValue('breeds', response.data);
                return response;
        }
    }

    async searchOwnerAnimals({ search, owner }) {
        const response = await util.fetchApi(`/api/animals/`, { publish: true, method: 'GET' }, { search, owner });
        console.log('searchOwnerAnimals', response);
        switch (response.status) {
            case 200:
                this.updateKeyValue('searchResult', response.data);
                return response;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async mergeAnimals({ currentAnimal, mergeWith }) {
        console.log('mergeAnimals', currentAnimal, mergeWith);
        const response = await util.fetchApi(`/api/animals/mergeAnimals`, { publish: true, method: 'PATCH' }, {
            currentAnimal,
            mergeWith,
        });
        console.log('mergeAnimals', response);
        switch (response.status) {
            case 200:
                return response;
            case 401:
                break;
        }
    }
}

const store = new AnimalStore();
export default store;
