import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import localUtil from '../../lib/util';

@observer
class FileList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    viewFile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { url, title } = e.currentTarget.closest('button').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightLarge } = appState;
        appState.openDrawerRight('fileViewer', {
            height: drawerHeightLarge,
            title,
            url,
        }, drawerLevel + 1);
    }

    render() {
        const {
            files,
        } = this.props;

        return (<>
            {files && files.map((file, idx) => {
                return (<>
                    <button
                        type='button'
                        class='btn btn-sm btn-link ml-2 p-0 text-left'
                        onClick={this.viewFile}
                        data-url={file.s3Link}
                        data-title={file.name}
                    >
                        <i class={`fa-duotone ${localUtil.resolveFontawesomeFiletype(file.ext)}`} /> {file.name}
                    </button>
                </>);
            })}
        </>);
    }
}

export default FileList;
