import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import ImageScroller from '../../components/imagescroller';
import HelpText from '../../components/gui/helpText';
import Input from '../../components/form/input';
import GuiList from '../../components/gui/list';
import UserProfileInline from '../../components/user/profileInline';

import localUtil from '../../lib/util';
import { QRCodeSVG } from 'qrcode.react';

const MARKDOWN_OPTIONS = {
	pedantic: false,
	gfm: true,
	breaks: true,
	sanitize: false,
	smartLists: true,
	smartypants: true,
	xhtml: true,
    highlight: function(code, lang) {
        const hljs = require('highlight.js');
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
      },
    langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

function conventionImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3LargeLink}`;
    return imgSrc;
}

function participantExists(participants, key, value) {
    return participants.some(participant => participant[key] === value);
}

function memberExists(members, key, value) {
    return members.some(member => member[key] === value);
}

function generateArray(X) {
    const myNewShinyArray = [...Array(parseInt(X, 10) + 1).keys()];
    myNewShinyArray.shift();
    return myNewShinyArray;
}


@withText(props => ({
    searchTitle: <Text id='input.search-for-user'>Search for user</Text>,
    otpTitle: <Text id='input.otp-input'>Enter OTP</Text>,
}))
@observer
class ConventionView extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
            sections: fields.get('convention'),
            editEnabled: true,
        };
        this.mainContainer = null;
        this.countdownTimer = null;
        this.updateTimer = {};
        this.refs = {};
    }

    loadAll = async (props = this.props, emptyFirst = false) => {
        const { id } = props;
        const { conventionStore, userStore } = this.props.stores;
        const { user } = userStore;
        if (emptyFirst) {
            conventionStore.updateKeyValue('conventions', {});
        }
        conventionStore.setRefreshPage(() => {
            this.loadAll();
        });

        await conventionStore.load(id, false, { addData: ['tags'] });
        const { convention } = conventionStore;
        await conventionStore.getJwt(convention.uuidv4);
        await this.loadResults();

        conventionStore.updateKeyValue('newConvention', {
            title: convention.title,
            body: convention.body,
            images: convention.images,
        });

        if (convention && convention.participants && convention.participants.length && participantExists(convention.participants, 'id', user.id)) {
            this.setState({ signedUp: true });
        }
        if (convention && convention.members && convention.members.length && memberExists(convention.members, 'id', user.id)) {
            this.setState({ isConventionAdmin: true });
        } else if (convention.user === user.id) {
            this.setState({ isConventionAdmin: true });
        }

        const from = new Date(convention.startDate);
        const to = new Date(convention.endDate);
        const registrationFrom = new Date(convention.registrationStartDate || convention.startDate);
        registrationFrom.setHours(registrationFrom.getHours() - 1);
        const now = new Date();

        if (from < now && to > now) {
            this.setState({
                isValidDate: true,
                editEnabled: true,
                isRegistrationOpen: registrationFrom < now && to > now ? true : false,
                registrationFrom,
            });
        } else if (from > now) {
            this.setState({
                isInTheFuture: true,
                isValidDate: false,
                isRegistrationOpen: registrationFrom < now && to > now ? true : false,
                registrationFrom,
            });
            const { seconds } = util.dateDiff(now, from);
            this.setState({
                countdown: {
                    seconds,
                },
            });
            clearInterval(this.countdownTimer); // Clear any previous countdown
            this.countdownTimer = setInterval(() => {
                // Update the count down every 1 second
                const { seconds } = util.dateDiff(new Date(), convention.startDate);
                this.setState({
                    countdown: {
                        seconds,
                    },
                });
            }, 1000);
        } else {
            const { days } = util.dateDiff(now, from);
            this.setState({
                isInThePast: true,
                isValidDate: false,
                age: days,
                editEnabled: days < -7 ? false : true,
                registrationFrom,
            });
        }

        if (convention && convention.resultsLocked) {
            this.setState({
                editEnabled: !convention.resultsLocked,
            });
        }
    }

    editConvention = e => {
        const { id, title } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, timetrackersStore } = this.props.stores;
		const { drawerHeightLarge } = appState;
		appState.openDrawer('editConvention', {
            id,
			height: drawerHeightLarge,
            callback: () => {
                this.loadAll();
            },
		}, drawerLevel + 2);
	}

    addTag = e => {
        const { tagInput = '' } = this.state;
        const { conventionStore } = this.props.stores;
        const value = {
            name: tagInput.toLowerCase().trim(),
            date: new Date(),
        };
        value.md5 = md5(JSON.stringify(value)).toString();

        const field = 'tags';
        const object = conventionStore.convention;
        const tagExists = object[field] && object[field].find(tag => tag.name === value.name);
        if (!tagExists) {
            const localValue = object[field] ? [...object[field]] : [];
            conventionStore.saveField(object.id, field, value);
            conventionStore.updateField(object.id, field, [...localValue, value]);
        }
        this.setState({ tagInput: '' });
    }

    checkForEnter = (e) => {
        if (e.key === 'Enter') {
            this.addTag();
        }
    }

    getParticipantFields(convention) {
        if (convention.type === 'hurtig') {
            return ['10s', '10s', '10s', '10s', '8s', '8s', '8s', '8s', '6s', '6s', '6s', '6s'];
        }
        if (convention.type === 'nais') {
            return ['150s', '150s', '5x3s', '5x3s', '20s', '10s'];
        }
        if (convention.type === 'norgesfelt') {
            return ['Treff', 'Skvr', 'iX', 'Trekk'];
        }
        if (convention.type === 'felt') {
            return ['Treff', 'Skvr', 'iX', 'Trekk'];
        }
        return [];
    }

    filterParticipants = participant => {
        const { isConventionAdmin, showAll } = this.state;
        if (isConventionAdmin || showAll) {
            return true;
        }
        const { userStore } = this.props.stores;
        const { user } = userStore;
        return user.id === participant.id;
    }

    toggleShowAll = () => {
        const { showAll } = this.state;
        this.setState({ showAll: !showAll });
    }

    updateResult = e => {
        // const { isConventionAdmin } = this.state;
        // if (!isConventionAdmin) {
        //     return;
        // }
        const { value, dataset, name, min, max } = e.target;
        let inputValue = parseInt(value, 10);

        console.log({ min, max });
        const realMin = min ? parseInt(min, 10) : 0;
        const realMax = max ? parseInt(max, 10) : 50;
        if (inputValue < realMin) {
            inputValue = realMin;
        }
        if (inputValue > realMax) {
            inputValue = realMax;
        }

        // data-participant={participant.id}
        // data-id={targetResult?.id}
        // data-stage={stage.md5}
        // data-stand={stand}
        const { participant, idx, id, stage, stand, delay = 500 } = dataset;
        // console.log('updateResult', participant, id, idx, value);
        const timerId = `${participant}-${stage}-${stand}-${name}`;
        clearTimeout(this.updateTimer[timerId]);
        this.updateTimer[timerId] = setTimeout(() => {
            this.doUpdateResult(e, inputValue);
        }, parseInt(delay, 10));
    }

    doUpdateResult = async (e, value) => {
        const { dataset, name = 'scoreTotal' } = e.target;
        const { participant, idx, id, stage, stand } = dataset;
        // console.log('doUpdateResult', participant, id, idx, value);
        const { conventionStore, conventionResultStore } = this.props.stores;
        const { convention } = conventionStore;

        if (id) {
            await conventionResultStore.save({
                id: parseInt(id, 10),
                targetIndex: parseInt(idx, 10),
                participant: parseInt(participant, 10),
                convention: convention.id,
                stage,
                stand,
                [name]: parseInt(value, 10),
            });
        } else {
            await conventionResultStore.insert({
                targetIndex: parseInt(idx, 10),
                participant: parseInt(participant, 10),
                convention: convention.id,
                stage,
                stand,
                [name]: parseInt(value, 10),
            });
        }
        await this.loadResults();

        let nextStand = parseInt(stand, 10);
        let nextField = 'scoreTotal';
        switch (name) {
            case 'scoreTotal':
                nextField = 'scoreHits';
                break;
            case 'scoreHits':
                nextField = 'scoreTargets';
                break;
            case 'scoreTargets':
                nextField = 'scoreInner';
                break;
            case 'scoreInner':
                nextField = 'scoreHits';
                nextStand += 1;
                console.log('nextStand', nextStand);
                // nextField = 'scorePenalty';
                break;
        }

        const refKey = `${participant}-${stage}-${nextStand}-${nextField}`;
        console.log('refKey', refKey);
        if (this.refs[refKey]) {
            this.refs[refKey].focus();
        }
    }

    loadResults = async () => {
        const { conventionStore, conventionResultStore } = this.props.stores;
        const { convention } = conventionStore;
        await conventionResultStore.load({ query: { convention: convention.id } });
    }

    addImage = e => {
        const { id } = e.target.dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        appState.openDrawer('editConventionResultImage', {
            id,
            height: drawerHeightSmall,
        }, drawerLevel + 1);
    }

    viewImage = e => {
        const { id } = e.target.closest('td').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('viewConventionResultImage', {
            id,
            height: drawerHeightMedium,
        }, drawerLevel + 1);
    }

    viewEditImage = e => {
        const { id } = e.target.closest('i').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('viewConventionResultImage', {
            id,
            height: drawerHeightMedium,
        }, drawerLevel + 1);
    }

    closeDrawers = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer();
    }

    handleFocus = (e) => {
        e.target.select();
    }

    // handleContextMenu = (e) => {
    //     return ;
    //     e.preventDefault();
    // }

    newRound = async e => {
        const { type } = e.target.closest('button').dataset;
        const { conventionStore } = this.props.stores;
        await conventionStore.newRound(type);
        await this.loadAll();
    }

    addNewParticipant = async e => {
        const { id } = e.target.closest('button').dataset;
        const { conventionStore, userStore } = this.props.stores;
        const { searchUsersResult } = userStore;
        const user = searchUsersResult.find(user => user.id === parseInt(id, 10));
        await conventionStore.newParticipant(user);
        await this.loadAll();
    }

    editParticipant = async e => {
        const { id, md5, stage } = e.target.closest('i').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        appState.openDrawer('editConventionParticipant', {
            id,
            md5,
            stage,
            height: drawerHeightSmall,
        }, drawerLevel + 1);
    }

    addParticipant = async e => {
        const { id, md5 } = e.target.closest('button').dataset;
        const { conventionStore } = this.props.stores;
        const { convention } = conventionStore;
        const { participants } = convention;
        const user = participants.find(user => user.id === parseInt(id, 10));
        await conventionStore.newParticipant(user, md5);
        await this.loadAll();
    }

    addMe = async e => {
        const { md5 } = e.target.closest('button').dataset;
        const { conventionStore, userStore } = this.props.stores;
        const { user } = userStore;
        await conventionStore.newParticipant(user, md5);
        await this.loadAll();
    }

    editStage = async e => {
        const { id, md5 } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('editConventionStage', {
            id,
            md5,
            height: drawerHeightMedium,
        }, drawerLevel + 1);
    }

    searchInput = (e) => {
        const search = e.target.value;
        this.setState({ search });

        clearTimeout(this.searchTimer);
        if (search.length >= 3) {
            this.searchTimer = setTimeout(async () => {
                this.doSearch(search);
            }, 300);
        }
    }

    async doSearch(search) {
        const { userStore } = this.props.stores;
        if (search) {
            // console.log('doUpdateField.saveField', id, field, value);
            const result = await userStore.searchUsersAll({ search, showAll: 1 });
        } else {
            userStore.localUpdateField('searchUsersResult', []);
        }
    }

    clearSearch = () => {
        this.setState({ search: '' });
        this.doSearch('');
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.doSearch(this.state.search);
        } else if (e.key === 'Escape') {
            e.preventDefault();
            this.clearSearch();
        }
    }

    otpInput = e => {
        const userOtpIsValid = e.target.value.length === 6;
        this.setState({
            userOtp: e.target.value,
            userOtpIsValid,
        });
    }

    checkOtp = async e => {
        const { userOtp } = this.state;
        const { conventionStore } = this.props.stores;
        const { convention, jwtToken } = conventionStore;
        const response = await conventionStore.checkOtp(convention.uuidv4, userOtp);
        if (response.status === 200) {
            route(`/convention/${jwtToken}/signup`);
        } else {
            this.setState({
                userOtp: '',
                userOtpError: true,
            });
        }
    }

    componentDidMount() {
        this.loadAll(this.props, true);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps, true);
        }
    }

    componentWillUnmount() {
        clearInterval(this.countdownTimer);
    }

    render() {
        const {
            searchTitle,
            otpTitle,
        } = this.props;
        const {
            search,
            userOtp,
            userOtpIsValid,
            userOtpError,
        } = this.state;

        const {
            signedUp,
            isConventionAdmin,
            isValidDate,
            isInTheFuture,
            isInThePast,
            isRegistrationOpen,
            registrationFrom,
            countdown = {},
            age,
            editEnabled,
            showAll,
        } = this.state;
        const { appState, userStore, conventionStore, conventionResultStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary, language, searchUsersResult } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { convention, jwtToken, otp = '' } = conventionStore;
        const { conventionResults } = conventionResultStore;
        const apiServer = util.getApiServer();

        const otpFirstPart = otp.slice(0, 3);
        const otpSecondPart = otp.slice(3, 6);

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class={`font-weight-lighter px-3 mt-0 box-header w-100 position-relative`}>
                        {convention.title}
                        {isAdmin && <div class={`position-absolute`} style={`top: 0px; right: 10px; z-index: 1000;`}>
                            <button
                                onClick={this.editConvention}
                                class={`btn btn-sm btn-outline-primary rounded-pill mr-2`}
                                data-id={convention.id}
                            >
                                <i class={`fa-regular fa-pen`} />
                            </button>
                        </div>}
                        {/* <div class='' style='margin-right: 65px;'>
                            <span onClick={this.editConvention} class={`text-primary ${linkTextClass}`} style={`${linkTextStyle}`}>
                                <i class={`fa-regular fa-pen ml-3 mr-1`} />
                            </span>
                        </div> */}
                    </div>
                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex'>
                                {/* {JSON.stringify(convention)} */}
                                <ImageScroller
                                    images={convention.images}
                                    stores={this.props.stores}
                                    showImg={img => conventionImg(img, this.props, '1920x')}
                                    showImageInfo={false}
                                />
                            </div>

                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3 mt-1`}>
                                {[
                                    'type',
                                    'startDate',
                                    'endDate',
                                    'ingress',
                                    'body',
                                ].map(key => {
                                    if (util.isUndefined(convention[key])) {
                                        return '';
                                    }
                                    const field = fields.getField('convention', key);
                                    if (!field) {
                                        console.log('Missing field:', key);
                                    }
                                    const displayValue = field.displayValue || (value => value);
                                    return (<>
                                        {(convention[key] || convention[key] === false) && <div class='d-flex flex-row'>
                                            <span class='text-muted font-weight-light'>
                                                {field.title}
                                            </span>
                                            {field.type === 'textarea' ? <>
                                                <div class='ml-2  mb-4 text-wrap' style='max-height: 20vh; overflow: auto;'>
                                                    <Markdown markdown={displayValue(convention[key])} markedOpts={MARKDOWN_OPTIONS} />
                                                </div>
                                            </> : <>
                                                <span class='ml-2'>
                                                    {field.type === 'toggle' ? <>
                                                        {convention[key] ? <>
                                                            <i class='fa-solid fa-check text-success' />
                                                        </> : <>
                                                            <i class='fa-solid fa-times text-danger' />
                                                        </>}
                                                    </> : <>
                                                        {displayValue(convention[key])}
                                                    </>}
                                                </span>
                                            </>}
                                        </div>}
                                    </>);
                                })}
                            </div>

                            {/* isConventionAdmin: {isConventionAdmin ? 'true' : 'false'}<br /> */}
                            {/* isRegistrationOpen: {isRegistrationOpen ? 'true' : 'false'}<br /> */}
                            {/* registrationFrom: {registrationFrom ? util.formatDate(registrationFrom, { hour12: false, hour: '2-digit', minute: '2-digit' }) : 'null'}<br /> */}

                            <div class={`d-flex flex-column justify-content-center align-items-center mt-3 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>

                                {isConventionAdmin ? <>
                                    {isRegistrationOpen ? <>
                                        <QRCodeSVG value={`https://${window.location.hostname}/#/convention/${jwtToken}/signup`} size={256} />
                                        <a href={`/convention/${jwtToken}/signup`} onClick={this.closeDrawers}>Sign up for admins!</a><br />
                                        <div
                                            class='d-flex text-center'
                                            style={`
                                                font-size: 2.5rem;
                                                font-weight: bold;
                                                letter-spacing: 10px;
                                                font-family: 'Courier New', Courier, monospace;
                                            `}
                                        >
                                            {otpFirstPart} {otpSecondPart}
                                        </div>
                                    </> : <>
                                        {isInTheFuture ? <>
                                            <div class='alert alert-success rounded-lg' role='alert'>
                                                <Text id='convention.is-admin'>You are admin of this convention. A QR-Code will appear here 1 hour before the start.</Text>
                                            </div>
                                        </> : <></>}
                                    </>}
                                </> : <>
                                    {isRegistrationOpen ? <>
                                        {!signedUp ? <>
                                            <div class='alert alert-danger rounded-lg d-flex flex-column align-items-center' role='alert'>
                                                <i class='fa-duotone fa-user-magnifying-glass mb-2' style='font-size: 3.0em;' />
                                                <Text id='convention.not-admin'>Find an admin and ask for the QR-code to attend this convention.</Text>
                                            </div>
                                            <div class='d-flex flex-column justify-content-center mt-4'>
                                                <Text id='convention.log-in-with-code'>Log in with code:</Text>

                                                <div class='input-group'>
                                                    <div class='input-group-prepend'>
                                                        <span class='input-group-text rounded-pill-left'>
                                                            <i class='fa-duotone fa-key'></i>
                                                        </span>
                                                    </div>
                                                    <input
                                                        class={`form-control form-control-lg ${userOtpIsValid ? '' : 'rounded-pill-right'}`}
                                                        type='text'
                                                        value={userOtp}
                                                        placeholder={`${otpTitle}`}
                                                        onInput={this.otpInput}
                                                        size='6'
                                                        maxLength={6}
                                                        // onBlur={this.clearSearch}
                                                    />

                                                    {userOtpIsValid && <div class='input-group-append'>
                                                        <button
                                                            // disabled={disabled}
                                                            class={`btn btn-primary rounded-pill-right`}
                                                            type='button'
                                                            onClick={this.checkOtp}
                                                        >
                                                            <i class='fa-duotone fa-right-to-bracket' /> <span class='d-none d-md-inline-block'><Text id='convention.login-with-code'>Next</Text></span>
                                                        </button>
                                                    </div>}
                                                </div>

                                                {userOtpError ? <>
                                                    <div class='alert alert-danger rounded-lg mt-2' role='alert'>
                                                        <Text id='convention.wrong-code'>Wrong code!</Text>
                                                    </div>
                                                </> : <></>}

                                            </div>

                                        </> : <>
                                            <i class='fa-regular fa-face-smile text-success' style='font-size: 3.0em;' />
                                        </>}
                                    </> : <></>}
                                </>}

                                {isInTheFuture ? <>
                                    <Text id='convention.not-valid-date'>This convention is not active.</Text>
                                    <div class='d-flex flex-column justify-content-center mt-4'>
                                        <Text id='convention.starts-in'>Starts in:</Text>
                                        <span class='display-4 text-monospace'>
                                            {countdown && countdown.seconds >= 0 ? <>
                                                {util.secToHms(countdown.seconds)}
                                            </> : <></>}
                                        </span>
                                    </div>
                                </> : <>
                                    {!isRegistrationOpen ? <>
                                        <Text id='convention.done'>This convention is done!</Text>
                                        {convention.startDate ? <>
                                            <div class='d-flex flex-column justify-content-center mt-4'>
                                                <Text id='convention.has-been'>Has been:</Text>
                                                <span class='text-monospace' style='font-size: 1.5em;'>
                                                    {util.formatDate(convention.startDate, { hour12: false, hour: '2-digit', minute: '2-digit' })}
                                                </span>
                                            </div>
                                        </> : <></>}
                                    </> : <></>}
                                </>}
                            </div>

                            {signedUp ? <>
                                <div class={`d-flex flex-column mt-3 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                    <div class='d-flex flex-row justify-content-center'>
                                        <div class='text-success'>
                                            <i class='fa-solid fa-check text-success' />
                                            <span class='ml-2'>
                                                {isInTheFuture || isRegistrationOpen ? <>
                                                    <Text id='convention.signed-up'>You are signed up to this convention!</Text>
                                                </> : <>
                                                    <Text id='convention.signed-up-done'>You have attended this convention!</Text>
                                                </>}
                                            </span>
                                        </div>
                                    </div>
                                    {isInTheFuture || isRegistrationOpen ? <>
                                        <UserProfileInline stores={this.props.stores} />
                                    </> : <></>}
                                </div>
                            </> : <></>}
                            {/* <xmp>{JSON.stringify(convention.participants, null, 4)}</xmp> */}

                            {convention.stages && convention.stages.length ? <>
                                {convention.stages.map((stage, idx) => {
                                    const isAllowedToEdit = editEnabled && !parseInt(stage.stageClosed, 10);
                                    const isFieldShooting = ['norgesfelt', 'felt'].includes(stage.type);
                                    const participants = convention[`participants${idx > 0 ? idx + 1 : ''}`];
                                    const isSignedUpToStage = participants && participants.some(participant => participant.id === user.id);

                                    return (<>
                                        <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mb-1 mt-3'>
                                            {stage.name} ({stage.type})
                                            <div class='d-flex align-items-center justify-content-center'>
                                                {signedUp && !isSignedUpToStage ? <>
                                                    <button
                                                        class='btn btn-sm btn-primary rounded-pill'
                                                        data-md5={stage.md5}
                                                        onClick={this.addMe}
                                                    >
                                                        <i class='fa-duotone fa-user-plus' /> <Text id='convention.add-me'>Add me</Text>
                                                    </button>
                                                </> : <></>}
                                                {!isConventionAdmin && <button class={`btn btn-sm btn-${showAll ? 'primary' : 'outline-secondary'} rounded-pill ml-3`} onClick={this.toggleShowAll}>
                                                    <i class='fa-duotone fa-users' />
                                                </button>}
                                                {isConventionAdmin && <>
                                                    <button
                                                        class='btn btn-sm btn-outline-primary rounded-pill ml-3'
                                                        data-id={convention.id}
                                                        data-md5={stage.md5}
                                                        onClick={this.editStage}
                                                    >
                                                        <i class='fa-duotone fa-edit' />
                                                    </button>
                                                </>}
                                            </div>
                                        </div>
                                        <div class={`d-flex flex-column mt-0 mb-5 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} overflow-auto`}>
                                            <div class='table-reponsive w-100'>
                                                <table class='table table-sm table-striped'>
                                                    <thead>
                                                        <tr>
                                                            <th class='text-muted w-5'><small>&nbsp;</small></th>

                                                            {isFieldShooting && !isAllowedToEdit ? <>
                                                                {generateArray(stage.stages || 10).map(stand => {
                                                                    return (<>
                                                                        <th class='text-muted text-center w-5'><small>{stand}</small></th>
                                                                    </>);
                                                                })}
                                                            </> : <>
                                                                {['norgesfelt', 'felt'].includes(stage.type) ? <>
                                                                    <th class='text-muted text-center'><small><i class='fa-solid fa-crosshairs-simple' /></small></th>
                                                                </> : <></>}
                                                                {this.getParticipantFields(stage).map((field, index) => {
                                                                    return (<>
                                                                        <th class='text-muted font-weight-lighter'><small>{field}</small></th>
                                                                    </>);
                                                                })}
                                                            </>}

                                                            {['norgesfelt', 'felt'].includes(stage.type) ? <>
                                                                <th class='text-muted text-center'><small><Text id='input.innerX'>iX</Text></small></th>
                                                            </> : <></>}
                                                            <th class='text-muted text-center'><small><Text id='input.totalScore'>Total</Text></small></th>
                                                            <th class='text-muted text-center'><small>&nbsp;</small></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {participants && participants.filter(this.filterParticipants).map((participant, participantIdx) => {
                                                            const participantResults = conventionResults.filter(result => result.participant === participant.id && result.stage === stage.md5);
                                                            let totalScore = 0;
                                                            let totalIx = 0;
                                                            let standScore = 0;
                                                            const isYou = participant.id === user.id;

                                                            if (!isAllowedToEdit && isFieldShooting) {
                                                                return(<>
                                                                    <tr>
                                                                        <td class='p-0 middle'>
                                                                            <nobr>
                                                                                {localUtil.displayName(participant)} {isYou ? `(You)` : ''} <span class='font-weight-lighter'>- {participant.class}</span>
                                                                            </nobr>
                                                                        </td>
                                                                        {generateArray(stage.stages || 10).map(stand => {
                                                                            standScore = 0;
                                                                            const targetResult = participantResults.find(result => result.stand === stand);
                                                                            standScore += targetResult?.scoreHits || 0;
                                                                            standScore += targetResult?.scoreTargets || 0;
                                                                            if (targetResult?.scorePenalty) {
                                                                                standScore -= targetResult.scorePenalty;
                                                                            }
                                                                            totalScore += standScore;
                                                                            totalIx += targetResult?.scoreInner || 0;
                                                                            return (<>
                                                                                <td class='text-center' data-id={targetResult?.id} onClick={this.viewImage}>
                                                                                    {standScore}/<span class='font-weight-lighter'>{targetResult?.scoreInner || 0}</span>
                                                                                </td>
                                                                            </>);
                                                                        })}
                                                                        <td class='text-center font-weight-lighter'>{totalIx}</td>
                                                                        <td class='text-center font-weight-bolder'>{totalScore}</td>
                                                                    </tr>
                                                                </>);
                                                            }

                                                            if (isFieldShooting) {
                                                                const totalStands = stage.stages || 10;
                                                                return (<>
                                                                    {participantIdx > 0 && <>
                                                                        <tr>
                                                                            <th class='text-muted'><small>&nbsp;</small></th>
                                                                            {isFieldShooting && !isAllowedToEdit ? <>
                                                                                {generateArray(stage.stages || 10).map(stand => {
                                                                                    return (<>
                                                                                        <th class='text-muted text-center'><small>{stand}</small></th>
                                                                                    </>);
                                                                                })}
                                                                            </> : <>
                                                                                {['norgesfelt', 'felt'].includes(stage.type) ? <>
                                                                                    <th class='text-muted text-center'><small><i class='fa-solid fa-crosshairs-simple' /></small></th>
                                                                                </> : <></>}
                                                                                {this.getParticipantFields(stage).map((field, index) => {
                                                                                    return (<>
                                                                                        <th class='text-muted font-weight-lighter'><small>{field}</small></th>
                                                                                    </>);
                                                                                })}
                                                                            </>}
                                                                            {['norgesfelt', 'felt'].includes(stage.type) ? <>
                                                                                <th class='text-muted text-center'><small><Text id='input.innerX'>iX</Text></small></th>
                                                                            </> : <></>}
                                                                            <th class='text-muted text-center'><small><Text id='input.totalScore'>Total</Text></small></th>
                                                                            <th class='text-muted text-center'><small>&nbsp;</small></th>
                                                                        </tr>
                                                                    </>}

                                                                    {generateArray(totalStands).map(stand => {
                                                                        standScore = 0;
                                                                        const targetResult = participantResults.find(result => result.stand === stand);
                                                                        standScore += targetResult?.scoreHits || 0;
                                                                        standScore += targetResult?.scoreTargets || 0;
                                                                        if (targetResult?.scorePenalty) {
                                                                            standScore -= targetResult.scorePenalty;
                                                                        }
                                                                        totalScore += standScore;
                                                                        totalIx += targetResult?.scoreInner || 0;
                                                                        return (<>
                                                                            <tr>
                                                                                {isAllowedToEdit ? <>
                                                                                    {stand === 1 && <>
                                                                                        <td
                                                                                            class={`middle p-0`}
                                                                                            rowspan={totalStands}
                                                                                            style={'background-color: rgba(0,0,255,0.1);'}
                                                                                        >
                                                                                            <div class='vertical-text'>
                                                                                            <nobr>
                                                                                                {localUtil.displayName(participant)} {isYou ? `(You)` : ''} <span class='font-weight-lighter'>- {participant.class}</span>
                                                                                                {isConventionAdmin || isYou ? <>
                                                                                                    <i
                                                                                                        class='fa-solid fa-edit mt-2'
                                                                                                        data-id={convention.id}
                                                                                                        data-md5={participant.md5}
                                                                                                        data-stage={stage.md5}
                                                                                                        onClick={this.editParticipant}
                                                                                                    />
                                                                                                </> : <></>}
                                                                                            </nobr>
                                                                                            </div>
                                                                                        </td>
                                                                                    </>}
                                                                                </> : <>
                                                                                    <td class='p-0 middle'>
                                                                                        <nobr>
                                                                                            {localUtil.displayName(participant)} {isYou ? `(You)` : ''} <span class='font-weight-lighter'>- {participant.class}</span>
                                                                                            {isConventionAdmin || isYou ? <>
                                                                                                <i
                                                                                                    class='fa-duotone fa-edit ml-2'
                                                                                                    data-id={convention.id}
                                                                                                    data-md5={participant.md5}
                                                                                                    data-stage={stage.md5}
                                                                                                    onClick={this.editParticipant}
                                                                                                />
                                                                                            </> : <></>}
                                                                                        </nobr>
                                                                                    </td>

                                                                                </>}
                                                                                <td
                                                                                    class='text-center font-weight-lighter middle p-0'
                                                                                    style={'background-color: rgba(0,0,255,0.1);'}
                                                                                >
                                                                                    {stand}
                                                                                </td>
                                                                                <td class='text-center p-0'>
                                                                                    {isAllowedToEdit ? <>
                                                                                        <input
                                                                                            type='tel'
                                                                                            pattern='[0-9]*'
                                                                                            inputmode='numeric'
                                                                                            min='0'
                                                                                            max='6'
                                                                                            class='form-control form-control-sm py-0 px-1 text-center'
                                                                                            style='width: 40px; min-width: 40px; max-width: 40px; font-size: 1.1rem; line-height: 1.2rem;'
                                                                                            size='2'
                                                                                            data-participant={participant.id}
                                                                                            data-id={targetResult?.id}
                                                                                            data-stage={stage.md5}
                                                                                            data-stand={stand}
                                                                                            data-delay={'50'}
                                                                                            onInput={this.updateResult}
                                                                                            onFocus={this.handleFocus}
                                                                                            // onContextMenu={this.handleContextMenu}
                                                                                            name='scoreHits'
                                                                                            value={targetResult?.scoreHits >= 0 ? targetResult?.scoreHits : ''}
                                                                                            disabled={isConventionAdmin || isYou ? '' : 'disabled'}
                                                                                            ref={c => this.refs[`${participant.id}-${stage.md5}-${stand}-scoreHits`] = c}
                                                                                        />
                                                                                    </> : <>{targetResult?.scoreHits || ''}</>}
                                                                                </td>
                                                                                <td class='text-center p-0'>
                                                                                    {isAllowedToEdit ? <>
                                                                                        <input
                                                                                            type='tel'
                                                                                            pattern='[0-9]*'
                                                                                            inputmode='numeric'
                                                                                            min='0'
                                                                                            max='6'
                                                                                            class='form-control form-control-sm py-0 px-1 text-center'
                                                                                            style='width: 40px; min-width: 40px; max-width: 40px; font-size: 1.1rem; line-height: 1.2rem;'
                                                                                            size='2'
                                                                                            data-participant={participant.id}
                                                                                            data-id={targetResult?.id}
                                                                                            data-stage={stage.md5}
                                                                                            data-stand={stand}
                                                                                            data-delay={'50'}
                                                                                            onInput={this.updateResult}
                                                                                            onFocus={this.handleFocus}
                                                                                            // onContextMenu={this.handleContextMenu}
                                                                                            name='scoreTargets'
                                                                                            value={targetResult?.scoreTargets >= 0 ? targetResult?.scoreTargets : ''}
                                                                                            disabled={isConventionAdmin || isYou ? '' : 'disabled'}
                                                                                            ref={c => this.refs[`${participant.id}-${stage.md5}-${stand}-scoreTargets`] = c}
                                                                                        />
                                                                                    </> : <>{targetResult?.scoreTargets || ''}</>}
                                                                                </td>
                                                                                <td class='text-center p-0'>
                                                                                    {isAllowedToEdit ? <>
                                                                                        <input
                                                                                            type='tel'
                                                                                            pattern='[0-9]*'
                                                                                            inputmode='numeric'
                                                                                            min='0'
                                                                                            max='6'
                                                                                            class='form-control form-control-sm py-0 px-1 text-center'
                                                                                            style='width: 40px; min-width: 40px; max-width: 40px; font-size: 1.1rem; line-height: 1.2rem;'
                                                                                            size='2'
                                                                                            data-participant={participant.id}
                                                                                            data-id={targetResult?.id}
                                                                                            data-stage={stage.md5}
                                                                                            data-stand={stand}
                                                                                            data-delay={'50'}
                                                                                            onInput={this.updateResult}
                                                                                            onFocus={this.handleFocus}
                                                                                            // onContextMenu={this.handleContextMenu}
                                                                                            name='scoreInner'
                                                                                            value={targetResult?.scoreInner >= 0 ? targetResult?.scoreInner : ''}
                                                                                            disabled={isConventionAdmin || isYou ? '' : 'disabled'}
                                                                                            ref={c => this.refs[`${participant.id}-${stage.md5}-${stand}-scoreInner`] = c}
                                                                                        />
                                                                                    </> : <>{targetResult?.scoreInner || ''}</>}
                                                                                </td>
                                                                                <td class='text-center p-0'>
                                                                                    {isAllowedToEdit ? <>
                                                                                        <input
                                                                                            type='tel'
                                                                                            pattern='[0-9]*'
                                                                                            inputmode='numeric'
                                                                                            min='0'
                                                                                            max='6'
                                                                                            class='form-control form-control-sm py-0 px-1 text-danger text-center'
                                                                                            style='width: 40px; min-width: 40px; max-width: 40px; font-size: 1.1rem; line-height: 1.2rem;'
                                                                                            size='2'
                                                                                            data-participant={participant.id}
                                                                                            data-id={targetResult?.id}
                                                                                            data-stage={stage.md5}
                                                                                            data-stand={stand}
                                                                                            data-delay={'50'}
                                                                                            onInput={this.updateResult}
                                                                                            onFocus={this.handleFocus}
                                                                                            // onContextMenu={this.handleContextMenu}
                                                                                            name='scorePenalty'
                                                                                            value={targetResult?.scorePenalty >= 0 ? targetResult?.scorePenalty : ''}
                                                                                            disabled={isConventionAdmin || isYou ? '' : 'disabled'}
                                                                                            ref={c => this.refs[`${participant.id}-${stage.md5}-${stand}-scorePenalty`] = c}
                                                                                        />
                                                                                    </> : <>{targetResult?.scorePenalty || ''}</>}
                                                                                </td>
                                                                                <td class='text-center middle p-0'>{targetResult?.scoreInner || ''}</td>
                                                                                <td class='text-center middle p-0'>{standScore}</td>
                                                                                <td class='text-center middle p-0'>
                                                                                    {targetResult?.id ? <>
                                                                                        {targetResult?.images?.length ? <>
                                                                                            <i
                                                                                                class={`fa-duotone fa-eye text-muted`}
                                                                                                onClick={this.viewEditImage}
                                                                                                data-id={targetResult?.id}
                                                                                            />
                                                                                        </> : <>
                                                                                            <i
                                                                                                class={`fa-duotone fa-camera text-muted`}
                                                                                                onClick={this.addImage}
                                                                                                data-id={targetResult?.id}
                                                                                            />
                                                                                        </>}
                                                                                    </>: <></>}
                                                                                </td>
                                                                            </tr>
                                                                        </>);
                                                                    })}
                                                                    <tr style='background-color: rgba(255,0,0,0.1); border-bottom: 2px solid #000000;'>
                                                                        <td class='text-right font-weight-bolder' colspan='7'><Text id='input.totalScore'>Total</Text></td>
                                                                        <td class='text-center font-weight-lighter'>{totalIx}</td>
                                                                        <td class='text-center font-weight-bolder'>{totalScore}</td>
                                                                    </tr>
                                                                </>);
                                                            }

                                                            return (<>
                                                                <tr>
                                                                    <td class='middle'>
                                                                        <nobr>
                                                                            {localUtil.displayName(participant)} {isYou ? `(You)` : ''} <span class='font-weight-lighter'>- {participant.class}</span>
                                                                            {isConventionAdmin || isYou ? <>
                                                                                <i
                                                                                    class='fa-solid fa-edit ml-2'
                                                                                    data-id={convention.id}
                                                                                    data-md5={participant.md5}
                                                                                    data-stage={stage.md5}
                                                                                    onClick={this.editParticipant}
                                                                                />
                                                                            </> : <></>}
                                                                        </nobr>
                                                                    </td>
                                                                    {this.getParticipantFields(stage).map((field, index) => {
                                                                        const targetResult = participantResults.find(result => result.targetIndex === index);
                                                                        if (targetResult) {
                                                                            totalScore += targetResult.scoreTotal;
                                                                        }
                                                                        return (<>
                                                                            <td class='text-center'>
                                                                                {isAllowedToEdit ? <>
                                                                                    <input
                                                                                        type='tel'
                                                                                        pattern='[0-9]*'
                                                                                        inputmode='numeric'
                                                                                        min='0'
                                                                                        max='50'
                                                                                        class='form-control form-control-sm py-0 px-1 text-center'
                                                                                        style='width: 40px; min-width: 40px; max-width: 40px; font-size: 1.1rem; line-height: 1.2rem;'
                                                                                        size='2'
                                                                                        data-participant={participant.id}
                                                                                        data-idx={index}
                                                                                        data-id={targetResult?.id}
                                                                                        data-stage={stage.md5}
                                                                                        name='scoreTotal'
                                                                                        onInput={this.updateResult}
                                                                                        value={targetResult?.scoreTotal}
                                                                                        disabled={isConventionAdmin || isYou ? '' : 'disabled'}
                                                                                    />
                                                                                </> : <>{targetResult?.scoreTotal}</>}
                                                                            </td>
                                                                        </>);
                                                                    })}
                                                                    <td class='text-center'>
                                                                        <span style='font-size: 1.3rem; line-height: 1.2rem; font-weight: bold;'>
                                                                            {totalScore}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </>);
                                                        })}
                                                    </tbody>
                                                </table>

                                                {isConventionAdmin && idx > 0 && <>
                                                    <div class='font-weight-light d-flex flex-row justify-content-between mb-1 mt-3 text-danger'>
                                                        <Text id='convention.admin-add-new-user'>Admin add new user to stage</Text>:
                                                    </div>

                                                    {isInThePast ? <>
                                                        <div class='d-flex'>
                                                            <Text id='convention.in-the-past'>Convention is in the past and closed.</Text>
                                                        </div>
                                                    </> : <>
                                                        {convention.participants && convention.participants.map(participant => {
                                                            const isYou = participant.id === user.id;
                                                            const isMissingFromThisStage = !participants.some(p => p.id === participant.id);

                                                            if (isMissingFromThisStage) {
                                                                return (<>
                                                                    <button
                                                                        class={`btn btn-sm btn-outline-primary rounded-pill mx-1 my-1`}
                                                                        onClick={this.addParticipant}
                                                                        data-id={participant.id}
                                                                        data-md5={stage.md5}
                                                                    >
                                                                        <i class='fa-duotone fa-plus' /> {localUtil.displayName(participant)} {isYou ? `(You)` : ''}
                                                                    </button>
                                                                </>);
                                                            }
                                                            return '';
                                                        })}
                                                    </>}

                                                </>}

                                            </div>
                                        </div>
                                    </>);
                                })}
                            </> : <>
                                <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mb-1 mt-3'>
                                    <Text id='convention.participants'>Participants</Text>
                                </div>
                                <div class={`d-flex flex-column mt-0 mx-3 px-3 py-3 mt-1 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} overflow-auto`}>
                                    <div class='table-reponsive w-100'>
                                        <table class='table table-sm table-striped'>
                                            <thead>
                                                <tr>
                                                    <th class='text-muted'><Text id='input.name'>Name</Text></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {convention.participants && convention.participants.map(participant => {
                                                    const isYou = participant.id === user.id;
                                                    return (<>
                                                        <tr>
                                                            <td class='middle'>
                                                                <nobr>
                                                                    {localUtil.displayName(participant)} {isYou ? `(You)` : ''} <span class='font-weight-lighter'>- {participant.class}</span>
                                                                </nobr>
                                                            </td>
                                                        </tr>
                                                    </>);
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>}

                            {isConventionAdmin && <>
                                <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mb-1 mt-3 text-danger'>
                                    <Text id='convention.admin'>Admin</Text>
                                </div>
                                <div class={`d-flex flex-column mt-0 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} overflow-auto`}>
                                    {isInThePast ? <>
                                        <div class='d-flex'>
                                            <Text id='convention.in-the-past'>Convention is in the past and closed.</Text>
                                        </div>
                                    </> : <>
                                        <div class='d-flex'>
                                            <Text id='convention.add-new-round'>Add new round here:</Text>
                                        </div>
                                        <div class='d-flex flex-wrap justify-content-center'>
                                            <button
                                                class={`btn btn-sm btn-outline-primary rounded-pill mx-1 my-1`}
                                                onClick={this.newRound}
                                                data-type='nais'
                                            >
                                                <i class='fa-duotone fa-plus' /> <Text id='convention.new-nais'>New NAIS</Text>
                                            </button>
                                            <button
                                                class={`btn btn-sm btn-outline-primary rounded-pill mx-1 my-1`}
                                                onClick={this.newRound}
                                                data-type='hurtig'
                                            >
                                                <i class='fa-duotone fa-plus' /> <Text id='convention.new-hurtig'>New hurtig</Text>
                                            </button>
                                            <button
                                                class={`btn btn-sm btn-outline-primary rounded-pill mx-1 my-1`}
                                                onClick={this.newRound}
                                                data-type='felt'
                                            >
                                                <i class='fa-duotone fa-plus' /> <Text id='convention.new-felt'>New felt</Text>
                                            </button>
                                            <button
                                                class={`btn btn-sm btn-outline-primary rounded-pill mx-1 my-1`}
                                                onClick={this.newRound}
                                                data-type='norgesfelt'
                                            >
                                                <i class='fa-duotone fa-plus' /> <Text id='convention.new-norgesfelt'>New norgesfelt</Text>
                                            </button>
                                        </div>

                                        <div class='d-flex mt-5'>
                                            <Text id='convention.add-new-participant'>Add new participant:</Text>
                                        </div>
                                        <div class='d-flex flex-wrap justify-content-center'>
                                            <div class='flex-fill input-group'>
                                                <div class='input-group-prepend'>
                                                    <span class='input-group-text rounded-pill-left'>
                                                        <i class='fa-regular fa-magnifying-glass'></i>
                                                    </span>
                                                </div>
                                                <input
                                                    class={`form-control ${!search ? 'rounded-pill-right' : ''}`}
                                                    type='text'
                                                    value={search}
                                                    placeholder={`${searchTitle}`}
                                                    onInput={this.searchInput}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                                {search && <div class='input-group-append'>
                                                    <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.clearSearch}>
                                                        <i class='fa-solid fa-circle-xmark'></i>
                                                    </button>
                                                </div>}
                                            </div>
                                            {searchUsersResult && searchUsersResult.length ? <>
                                                <div class='d-flex flex-wrap justify-content-center'>
                                                    {searchUsersResult.map(user => {
                                                        return (<>
                                                            <button
                                                                class={`btn btn-sm btn-outline-primary rounded-pill mx-1 my-1`}
                                                                onClick={this.addNewParticipant}
                                                                data-id={user.id}
                                                            >
                                                                <i class='fa-duotone fa-plus' /> {localUtil.displayName(user)} {user.cellphone}
                                                            </button>
                                                        </>);
                                                    })}
                                                </div>
                                            </> : <></>}
                                        </div>
                                    </>}
                                </div>
                            </>}

                        </div>
                    </div>

                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                </div>
            </div>
        </>);
    }
}

export default ConventionView;
