import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

function hasJournalWithDiagnoses(visit) {
    // Check if the visit has journals and it's an array
    if (!visit.journals || !Array.isArray(visit.journals)) {
       return false;
    }
    // Use the some() method to check if any journal has diagnoses
    return visit.journals.some(
        (journal) =>
            Array.isArray(journal.diagnoses) && journal.diagnoses.length > 0
    );
}

@withText(props => ({
    saleTitle: <Text id='dyrejournal.sale-title'>Search for sale</Text>,
    searchTitle: <Text id='dyrejournal.search-title'>Search for sale</Text>,
    deleteAreYouSure: <Text id='dyrejournal.sale-delete-are-you-sure'>Are you sure you want to delete this sale?</Text>,
}))
@observer
class AllVisitsTable extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    loadJournals = async (animalIds, sale, journalIds) => {
        const { journalStore, saleStore } = this.props.stores;
        const response = await journalStore.load({
            query: {
                id: journalIds,
                // $or: [
                //     {
                //         animal: animalIds,
                //         sale,
                //     },
                //     {
                //         id: { $in: journalIds },
                //     },
                // ],
            },
            skipUpdate: true,
            addData: ['totalSales'],
        });
        saleStore.updateKeyValue('animalJournals', response);
    }

    loadAnimals = async visitorIds => {
        const { animalStore, saleStore } = this.props.stores;
        const response = await animalStore.load({
            query: {
                owner: visitorIds,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('visitorAnimals', response);

        await animalStore.load({
            query: {
                owner: visitorIds,
            },
        });
    }

    loadSales = async visitorIds => {
        if (!util.isArray(visitorIds) || visitorIds.length === 0) {
            return false;
        }
        const { saleStore } = this.props.stores;
        const response = await saleStore.load({
            query: {
                visitor: visitorIds,
                today: 1,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('currentVisitorSales', response);
        // this.loadAllVisitorSalePayments();
    }

    loadSalePayments = async saleId => {
        if (!util.isNumber(saleId)) {
            return false;
        }
        const { salePaymentStore, saleStore } = this.props.stores;
        const response = await salePaymentStore.load({
            query: {
                sale: saleId,
            },
            skipUpdate: true,
            limit: 5000,
        });
        saleStore.updateKeyValue('currentPayments', response);
    }

    loadAllSalePayments = async () => {
        const { salePaymentStore, saleStore } = this.props.stores;
        const { sales } = saleStore;
        const saleIds = sales.map(e => e.id);
        if (saleIds.length === 0) {
            return false;
        }
        const response = await salePaymentStore.load({
            query: {
                sale: saleIds,
            },
            skipUpdate: true,
            limit: 5000,
        });
        saleStore.updateKeyValue('allPayments', response);
    }

    loadAllVisitorSalePayments = async () => {
        const { salePaymentStore, saleStore } = this.props.stores;
        saleStore.updateKeyValue('visitorAllPayments', []);

        const { currentVisitorSales = [] } = saleStore;
        const saleIds = currentVisitorSales.map(e => e.id);
        if (saleIds.length === 0) {
            return false;
        }
        const response = await salePaymentStore.load({
            query: {
                sale: saleIds,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('visitorAllPayments', response);
    }

    onClickDeleteSale = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { deleteAreYouSure, callback = () => {} } = this.props;
        const { id } = e.target.closest('button').dataset;
        if (confirm(deleteAreYouSure)) {
            const { saleStore } = this.props.stores;
            const response = await saleStore.deleteSale(parseInt(id, 10));
            callback();
        }
    }

    onClickUndeleteSale = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { callback = () => {} } = this.props;
        const { id } = e.target.closest('button').dataset;
        const { saleStore } = this.props.stores;
        const response = await saleStore.save({
            id: parseInt(id, 10),
            offline: 0,
        });
        callback();
    }

    onClickViewVisit = async e => {
        const { id } = e.target.closest('tr').dataset;
        this.viewVisit(parseInt(id, 10));
    }

    viewVisit = async id => {
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore } = this.props.stores;
        saleStore.createNewSale({});

        const newSale = await saleStore.load(id, false, { skipUpdate: true });
        if (id) {
            await this.loadSalePayments(id);
        }
        if (newSale[0].visitors) {
            const visitorIds = newSale[0].visitors.map(e => e.id);
            // console.log('onClickViewVisit', visitorIds)
            await this.loadAnimals(visitorIds);
            await this.loadSales(visitorIds);
        }

        if (newSale[0].animals) {
            const journalIds = newSale[0].journals.map(e => e.id);
            const animalIds = newSale[0].animals.map(e => e.id);
            await this.loadJournals(animalIds, newSale[0].id, journalIds);
        }
        saleStore.updateKeyValue('newSale', newSale[0]);

        const { visitors, isProductionAnimal } = newSale[0];

        const isProductionAnimals = util.isDefined(isProductionAnimal) ? isProductionAnimal : visitors.some(v => v?.producerNumber);
        saleStore.updateKeyValue('isProductionAnimals', isProductionAnimals);

        appState.appContainerScrollTop();
        appState.toggleDrawerRight(false, drawerLevel);
    }

    createFikenCompanyInvoice = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { callback = () => {} } = this.props;
        const { id } = e.target.closest('button').dataset;
        const { fikenStore } = this.props.stores;
        const response = await fikenStore.createCompanyInvoice(id);
        // console.log('createFikenCompanyInvoice', response);
        callback();
    }

    createFikenCompanySale = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { callback = () => {} } = this.props;
        const { id } = e.target.closest('button').dataset;
        const { fikenStore } = this.props.stores;
        const response = await fikenStore.createCompanySale(id);
        // console.log('createFikenCompanyInvoice', response);
        callback();
    }

    sendFikenInvoice = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { callback = () => {} } = this.props;
        const { id } = e.target.closest('button').dataset;
        const { fikenStore } = this.props.stores;
        const response = await fikenStore.sendCompanyInvoice(id);
        // console.log('sendFikenInvoice', response);
        callback();
    }

    createTripletexInvoice = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { callback = () => {} } = this.props;
        const { id } = e.target.closest('button').dataset;
        const { tripletexStore } = this.props.stores;
        const response = await tripletexStore.createInvoice(id);
        // console.log('createTripletexInvoice', response);
        callback();
    }

    onClickGotoAnimaliaAuth = e => {
        e.preventDefault();
        const { userStore } = this.props.stores;
        const { user } = userStore;

        // Open popup window to Animalia Auth
        const apiServer = util.getApiServer();

        const animalia_redirect_uri = `${apiServer}/api/integrations/animalia/token-exchange`;
        const animalia_client_id = 'dyrejournal';
        const animalia_state = user.id;
        const animalia_scope = encodeURIComponent('openid profile offline_access');

        const animalia_auth_url = `https://staging-sso.animalia.no/authorize?redirect_uri=${animalia_redirect_uri}&client_id=${animalia_client_id}&state=${animalia_state}&auto_login=true&response_type=code&scope=${animalia_scope}`;
        window.open(animalia_auth_url, 'Animalia Auth', 'width=600,height=600');

        // set timeout to update userInfo
        setTimeout(() => {
            userStore.getInfo();
        }, 3000);
    }

    onClickPostAnimaliaVisit = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { callback = () => {} } = this.props;
        const { id } = e.target.closest('button').dataset;
        const { appState, userStore } = this.props.stores;
        const response = await appState.animaliaUploadVisit(id);

        console.log('onClickPostAnimaliaVisit.response', response);
        if (response.error) {
            alert(`DHP Error: ${response?.error?.error}`);
            await userStore.getInfo();
        }
        callback();
    }

    handleGotoSale = e => {
        e.preventDefault();
        e.stopPropagation();
        console.log('handleGotoSale', e.key);
        const { saleStore } = this.props.stores;
        const { sales, searchResults } = saleStore;
        const finalSales = searchResults && searchResults.length > 0 ? searchResults : sales;
        const idx = parseInt(e.key, 10) - 1;
        const currentSale = finalSales[idx];
        if (currentSale?.id) {
            this.viewVisit(currentSale.id);
        }
    }

    componentDidMount() {
        Mousetrap.bind(['1', '2', '3', '4', '5', '6', '7', '8', '9'], this.handleGotoSale);
    }

    componentWillUnmount() {
        // Unbind shortcuts
        Mousetrap.unbind(['1', '2', '3', '4', '5', '6', '7', '8', '9']);
    }

    render() {
        const { search, currentPage = 0, cutoffDate } = this.state;
        const {
            saleTitle,
            searchTitle,
            formatting = true,
            showDelete = true,
            activeStatus,
        } = this.props;
        const { userStore, saleStore } = this.props.stores;
        const { user, isAdmin, customer } = userStore;
        const { customerIds, language } = user;
        const hasMoreThanOneCustomer = customerIds && customerIds.length > 1;

        const darkmode = util.getNestedValue(user, 'settings.darkmode');

        const { sales, allPayments, searchResults, totalSales } = saleStore;
        const finalSales = searchResults && searchResults.length > 0 ? searchResults : sales;
        // const finalSales = sales;

        const isDebitorList = activeStatus === 99;
        let sumRestPayment = 0;

        const { featureFlags = {} } = customer;
        const { animalia = false, fiken = false, tripletex } = featureFlags;
        const showFiken = isAdmin && fiken;
        const showTripletex = isAdmin && tripletex;
        const showAnimalia = isAdmin && animalia;

        return (
            <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mt-3`}>
                <table class='table table-sm table-striped mb-0' style='font-size: 0.9em;'>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th class='d-none d-sm-table-cell'><Text id='dyrejournal.id'>Id</Text></th>
                            {hasMoreThanOneCustomer && <th class='d-none d-sm-table-cell text-center'><Text id='dyrejournal.customer'>Customer</Text></th>}
                            <th><Text id='dyrejournal.createdDate'>CreatedDate</Text></th>
                            <th><Text id='dyrejournal.saleDate'>SaleDate</Text></th>
                            <th><Text id='dyrejournal.title'>Title</Text></th>
                            <th class='text-center'><Text id='dyrejournal.animals'>Animals</Text></th>
                            <th class='text-center'><Text id='dyrejournal.journals'>Journals</Text></th>
                            <th class='text-center'><Text id='dyrejournal.status'>Status</Text></th>
                            <th class='text-center'><Text id='dyrejournal.payment-method'>Payment method</Text></th>
                            <th class='text-center'><Text id='dyrejournal.total'>Total</Text></th>
                            <th class='text-center'><Text id='dyrejournal.rest'>Rest</Text></th>
                            {!isDebitorList && <th class='text-center'><Text id='dyrejournal.payments'>Payments</Text></th>}
                            {showFiken && <th class='text-center'>Fiken</th>}
                            {showTripletex && <th class='text-center'>Tripletex</th>}
                            {showAnimalia && <th class='text-center'>DHP</th>}
                            {showDelete && <th class='text-center'>&nbsp;</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {(finalSales && finalSales.length > 0) && finalSales.map((e, idx) => {
                            const saleStatus = saleStore.getSaleStatusById(e.status);
                            const saleStatusText = saleStore.getSaleStatusById(e.status, language);
                            const saleStatusIcon = saleStore.getSaleStatusIcon(saleStatus);
                            const saleStatusClass = saleStore.getSaleStatusClass(saleStatus);
                            const salePayments = allPayments.filter(p => p.sale === e.id);

                            let paymentRestAmount = 0;
                            const hasPayments = salePayments && salePayments.length > 0;
                            if (hasPayments) {
                                paymentRestAmount = saleStore.sumRestPayments(salePayments, saleStore.sumTotal(e.products, false), false, false);
                            }

                            if (isDebitorList) {
                                sumRestPayment += e.restPayment;
                            }

                            let saleIsPaid = (!!e.paidDate && !!e.paymentMethod)
                                || (hasPayments && paymentRestAmount === 0)
                                || e.paymentMethod === 'creditNote'
                                || e.paymentMethod === 'invoiceCredited'
                                || e.paymentMethod === 'saleCredited';
                            const isAllowedToDelete = !e.saleNumber;

                            const hasDiagnoses = hasJournalWithDiagnoses(e);

                            return (<>
                                <tr onClick={this.onClickViewVisit} data-id={e.id} class={`${e.offline ? 'deleted text-muted' : ''}`}>
                                    <td class='font-weight-lighter text-muted'>{idx + 1}</td>
                                    <td class='d-none d-sm-table-cell text-center'>
                                        <nobr>
                                            {e.offline ? <>
                                                <button
                                                    class='btn btn-sm btn-link text-danger'
                                                    onClick={this.onClickUndeleteSale}
                                                    data-id={e.id}
                                                >
                                                    <i class='fa-duotone fa-trash-slash' />
                                                </button>
                                            </> : ''}
                                            {e.saleNumber ? <>{e.saleNumber}</> : <>n/a</>}
                                        </nobr>
                                    </td>
                                    {hasMoreThanOneCustomer && <td class='d-none d-sm-table-cell text-center'>
                                        {e.customer}
                                    </td>}
                                    <td>
                                        <nobr>{formatting ? util.formatDate(e.createdDate || e.saleDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' }) : util.isoDate(e.createdDate, false)}</nobr>
                                    </td>
                                    <td>
                                        <nobr class='text-muted'>{formatting ? util.formatDate(e.saleDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' }) : util.isoDate(e.saleDate, false)}</nobr>
                                    </td>
                                    <td>
                                        {e.isProductionAnimal ? <i class='fa-duotone fa-cow text-muted mr-2' /> : ''}

                                        {e.title || 'no title'}<br />
                                        {e.comments && e.comments.length > 0 && <>
                                            <small style='line-height: 1.0em;'>
                                                <div class='d-flex flex-column'>
                                                    {e.comments && e.comments.length > 0 && e.comments.map((comment, idx) => {
                                                        return (<>
                                                            <div>
                                                                <nobr class='font-weight-lighter'>{util.formatDate(comment.date, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}</nobr>: {comment.comment}
                                                            </div>
                                                        </>);
                                                    })}
                                                </div>
                                            </small>
                                        </>}
                                    </td>
                                    <td class='text-center'>{e.animals?.length > 0 ? e.animals.length : ''}</td>
                                    <td class='text-center'>{e.journals?.length > 0 ? e.journals.length : ''}</td>
                                    <td class='text-center'>
                                        <nobr>
                                            {/* e.status: {e.status}:: */}
                                            {saleIsPaid ? <>
                                                {e.status === 8 ? <>
                                                    <span class={`text-success`}><i class={saleStore.getSaleStatusIcon(e.paymentMethod)} /> {saleStore.getSaleStatusById(saleStore.getSaleStatus(e.paymentMethod), language)}</span>
                                                </> : <>
                                                    <span class={`text-${saleStore.getSaleStatusClass('paid')}`}><i class={saleStore.getSaleStatusIcon('paid')} /> {saleStore.getSaleStatusById(1, language)}</span>
                                                </>}
                                                {/* paymentRestAmount: {paymentRestAmount}:: */}
                                            </> : <>
                                                <span class={`text-${saleStatusClass}`}><i class={saleStatusIcon} /> {saleStatusText}</span>
                                            </>}
                                            {/* saleIsPaid: {saleIsPaid ? 'true' : 'false'}
                                            e.paidDate: {e.paidDate ? util.formatDate(e.paidDate, { month: '2-digit', year: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' }) : 'n/a'}
                                            e.paymentMethod: {e.paymentMethod}
                                            paymentRestAmount: {paymentRestAmount} */}
                                        </nobr>
                                    </td>
                                    <td class='text-center'>
                                        <i class={saleStore.getPaymentMethodIcon(e.paymentMethod)} /> {saleStore.getPaymentMethodText(e.paymentMethod, language)}
                                        {['cash', 'card', 'invoice', 'account', 'vipps', 'partial'].includes(e.paymentMethod) ? <>
                                            {salePayments && salePayments.length > 0 ? <>
                                            </> : <>
                                                {e.status > 0 && paymentRestAmount > 0 && <>
                                                    <br /><i class='fa-solid text-danger fa-triangle-exclamation mr-2' /> <Text id='dyrejournal.no-payments'>No payments</Text>
                                                </>}
                                            </>}
                                        </> : <>
                                        </>}
                                    </td>
                                    <td class='text-right'>
                                        <nobr>{saleStore.sumTotal(e.products, formatting)}</nobr>
                                    </td>
                                    <td class='text-right'>
                                        {e.totalCost ? <>
                                            <nobr>{formatting ? util.format(e.restPayment, 2) : e.restPayment}</nobr>
                                        </> : <>
                                            {salePayments && salePayments.length > 0 && <>
                                                <nobr>{saleStore.sumRestPayments(salePayments, saleStore.sumTotal(e.products, false), formatting, false)}</nobr>
                                            </>}
                                        </>}
                                    </td>
                                    {!isDebitorList && <td classs='text-left'>
                                        <small>
                                            <div class='d-flex flex-column'>
                                                {salePayments && salePayments.length > 0 && salePayments.map(payment => {
                                                    return (<>
                                                        <div class='d-flex flex-row justify-content-between'>
                                                            <nobr class='text-muted mr-2'>
                                                                {util.formatDate(payment.paidDate, { month: '2-digit', year: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}
                                                            </nobr>
                                                            <nobr>
                                                                {formatting ? util.format(payment.amount, 2) : payment.amount} {saleStore.getPaymentMethodText(payment.method, language)}
                                                            </nobr>
                                                        </div>
                                                    </>);
                                                })}
                                            </div>
                                        </small>
                                    </td>}

                                    {showFiken && <td class='text-center'>
                                        <small>
                                            {e.paymentMethod === 'invoice' ? <>
                                                {e.fikenLocation ? <>
                                                    <Text id='dyrejournal.fiken-invoice-created'>Fiken Invoice created</Text>
                                                    {!e.fikenInvoiceSent ? <>
                                                        <button
                                                            class='btn btn-sm btn-outline-success rounded-pill py-0'
                                                            data-id={e.id}
                                                            onClick={this.sendFikenInvoice}
                                                        >
                                                            <i class='fa-duotone fa-paper-plane-top' /> <Text id='dyrejournal.fiken-send-invoice'>Fiken: Send Invoice</Text>
                                                        </button>
                                                    </>: <>
                                                        <span class='ml-1'>
                                                            <Text id='dyrejournal.fiken-invoice-sent'>and sent</Text>
                                                        </span>
                                                    </>}
                                                    {/* <a href={e.fikenLocation} target='_blank'><i class='fa-duotone fa-external-link' /> Fiken</a> */}
                                                </> : <>
                                                    {saleIsPaid ? <>
                                                        <button
                                                            class='btn btn-sm btn-outline-success rounded-pill py-0'
                                                            data-id={e.id}
                                                            onClick={this.createFikenCompanySale}
                                                        >
                                                            <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.fiken-create-sale'>Fiken: Create Sale</Text>
                                                        </button>
                                                    </> : <>
                                                        <button
                                                            class='btn btn-sm btn-success rounded-pill py-0'
                                                            data-id={e.id}
                                                            onClick={this.createFikenCompanyInvoice}
                                                        >
                                                            <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.fiken-create-invoice'>Fiken: Create Invoice</Text>
                                                        </button>
                                                    </>}
                                                </>}
                                            </> : <>
                                                {saleIsPaid && ['cash', 'card'].includes(e.paymentMethod) ? <>
                                                    {e.fikenLocation ? <>
                                                        <Text id='dyrejournal.fiken-sale-created'>Fiken Sale created</Text>
                                                        {/* <a href={e.fikenLocation} target='_blank'><i class='fa-duotone fa-external-link' /> Fiken</a> */}
                                                    </> : <>
                                                        <button
                                                            class='btn btn-sm btn-outline-success rounded-pill py-0'
                                                            data-id={e.id}
                                                            onClick={this.createFikenCompanySale}
                                                        >
                                                            <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.fiken-create-sale'>Fiken: Create Sale</Text>
                                                        </button>
                                                    </>}
                                                </> : <></>}
                                            </>}
                                        </small>
                                    </td>}

                                    {showTripletex && <td class='text-center'>
                                        <small>
                                            {e.paymentMethod === 'invoice' ? <>
                                                {e.tripletexResponse && e.tripletexResponse.id ? <>
                                                    <Text id='dyrejournal.tripletex-invoice-created'>Tripletex Invoice created</Text>
                                                </> : <>
                                                    <button
                                                        class='btn btn-sm btn-info rounded-pill py-0'
                                                        data-id={e.id}
                                                        onClick={this.createTripletexInvoice}
                                                    >
                                                        <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.tripletex-create-invoice'>Tripletex: Create Invoice</Text>
                                                    </button>
                                                </>}
                                            </> : <>
                                                {saleIsPaid && ['cash', 'card'].includes(e.paymentMethod) ? <>
                                                    {e.tripletexResponse && e.tripletexResponse.id ? <>
                                                        <Text id='dyrejournal.tripletex-sale-created'>Tripletex Sale created</Text>
                                                        {/* <a href={e.fikenLocation} target='_blank'><i class='fa-duotone fa-external-link' /> Fiken</a> */}
                                                    </> : <>
                                                        <button
                                                            class='btn btn-sm btn-outline-info rounded-pill py-0'
                                                            data-id={e.id}
                                                            onClick={this.createTripletexInvoice}
                                                        >
                                                            <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.tripletex-create-sale'>Tripletex: Create Sale</Text>
                                                        </button>
                                                    </>}
                                                </> : <></>}
                                            </>}
                                        </small>
                                    </td>}

                                    {showAnimalia && <td class='text-center'>
                                        <small>
                                            {e.isProductionAnimal && e.animals.length > 0 ? <>
                                                {user?.animalia?.error ? <>
                                                    {user?.animalia?.error}
                                                    <button
                                                        type='button'
                                                        class='btn btn-sm btn-primary rounded-pill ml-2'
                                                        onClick={this.onClickGotoAnimaliaAuth}
                                                    >
                                                        <nobr><i class='fa-duotone fa-download' /> <Text id='dyrejournal.animalia-auth'>Animalia Auth</Text></nobr>
                                                    </button>
                                                </> : <>
                                                    {hasDiagnoses ? <>
                                                        {/* {e.animaliaResponse ? <>
                                                            {JSON.stringify(e.animaliaResponse)}
                                                        </> : <></>} */}

                                                        {e.animaliaId ? <>
                                                            <i class='fa-duotone fa-check text-success' /> <Text id='dyrejournal.animalia-reported'>Reported to DHP</Text>
                                                        </> : <>
                                                            <button
                                                                class='btn btn-sm btn-primary rounded-pill py-0'
                                                                data-id={e.id}
                                                                onClick={this.onClickPostAnimaliaVisit}
                                                            >
                                                                <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.dhp-transfer'>DHP</Text>
                                                            </button>
                                                        </>}
                                                    </> : <>
                                                    <i class='fa-duotone fa-times text-danger' /> <Text id='dyrejournal.no-diagnoses'>No diagnoses</Text>
                                                    </>}
                                                </>}
                                            </> : <></>}
                                        </small>
                                    </td>}
                                    {showDelete && <td>
                                        {isAllowedToDelete ? <>
                                            {!e.offline && <>
                                                <button
                                                    type='button'
                                                    class='btn btn-sm btn-link text-danger rounded-pill p-0'
                                                    onClick={this.onClickDeleteSale}
                                                    data-id={e.id}
                                                >
                                                    <i class='fa-solid fa-trash' />
                                                </button>
                                            </>}
                                        </> : <>
                                            &nbsp;
                                        </>}
                                    </td>}
                                </tr>
                            </>);
                        })}
                        {isDebitorList && <>
                            <tr>
                                <td colspan='10' class='text-right font-weight-bold' style='font-size: 1.5em;'><Text id='dyrejournal.total'>Total</Text></td>
                                <td class='text-right font-weight-bold' style='font-size: 1.5em;'>
                                    {formatting ? util.format(sumRestPayment, 2) : sumRestPayment}
                                </td>
                            </tr>
                        </>}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default AllVisitsTable;
