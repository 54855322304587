import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import localUtil from '../../lib/util';

const MARGIN_TOP = localUtil.marginTop(false);
const MARGIN_BOTTOM = localUtil.marginBottom();
const SCROLLDOWN_LIMIT = 500;
const DRAWER_Z_INDEX = 1000000;

const actionRange = 200;
const indicateActionRange = 50;

@observer
class Drawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: '0px',
            pulledDown: 0,
        };
    }

    onCloseClick = (e) => {
        e.stopPropagation();
        const { onCloseClick, drawer, callback = () => {} } = this.props;
        if (onCloseClick) {
            onCloseClick();
            callback();
        } else {
            this.setState({ height: '0px' });
            setTimeout(() => {
                const { appState } = this.props.stores;
                appState.toggleDrawer(false, drawer);
                callback();
            }, 300);
        }
    }

    onScroll = (e) => {
        const { storyStore, teamStore, workoutStore } = this.props.stores;
        const { drawerComponent, loadMore = () => {} } = this.props;
        // Scroll to top function
        const element = e.target;
        const { scrolledDown } = this.state;
        if (element) {
            if (element.scrollTop > SCROLLDOWN_LIMIT && !scrolledDown) {
            // if (element.scrollTop > SCROLLDOWN_LIMIT) {
                this.setState({
                    scrolledDown: element.scrollTop,
                });
            } else if (element.scrollTop === 0) {
                this.setState({
                    scrolledDown: 0,
                });
            }
        }

        // Load more content function
        const { isAtBottom } = this.state;
        const marginToBottom = element.clientHeight;
        if (element.scrollTop + element.clientHeight + marginToBottom >= element.scrollHeight) {
            if (!isAtBottom) {
                this.setState({ isAtBottom: true });
                // console.log(`onScroll.isAtBottom.loadMore: ${drawerComponent}`);
                loadMore();
            }
        } else if (isAtBottom) {
            this.setState({ isAtBottom: false });
        }
    }

    animateHeight = (props) => {
        setTimeout(() => {
            this.doAnimateHeight(props);
        }, 1);
    }

    doAnimateHeight = (props = this.props) => {
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        const {
            height = drawerHeightSmall,
        } = props;
        this.setState({ height });
    }

    handleKeyDown = (e) => {
        const { appState } = this.props.stores;
        const topMostDrawer = appState.getTopMostDrawer();
        if (topMostDrawer !== this.props.drawer) {
            return;
        }

        e.stopPropagation();
        if (e.key === 'Escape') {
            this.onCloseClick(e);
        }
    }

    touchStart = (e) => {
        // console.log('touchStart', e);
        this.setState({
            startX: e.touches[0].clientX,
            startY: e.touches[0].clientY,
        });
    }

    touchEnd = (e) => {
        const { yDiff, pulledDown } = this.state;
        // console.log('touchEnd', e);
        this.setState({
            startY: undefined,
            startX: undefined,
            yDiff: 0,
            // pulledDown: yDiff + pulledDown,
        });
    }

    touchMove = (e) => {
        e.stopPropagation();
        const element = e.target.closest('.drawer-content');
        const { startY, startX, height } = this.state;

        const xUp = e.touches[0].clientX;
        const yUp = e.touches[0].clientY;

        const xDiff = startX - xUp;
        const yDiff = startY - yUp;
        const absXDiff = Math.abs(xDiff);
        const absYDiff = Math.abs(yDiff);
        if ( absXDiff > absYDiff ) { /* most significant */
            // Do nothing
            // console.log('x swipe', { startX, startY, xDiff });
        } else if ( yDiff > 0 ) {
            // Do nothing
            // console.log('up swipe', { startX, startY, yDiff });
        } else {
            // console.log('down swipe', { startY, startX, yDiff });
            // isAtTop
            if (element.scrollTop < 10) {
                if (absYDiff > actionRange) {
                    // console.log('action range', { startY, startX, yDiff });
                    this.onCloseClick(e);
                }
                if (absYDiff > indicateActionRange) {
                    // console.log('indicate action range', { startY, startX, yDiff });
                    this.setState({ yDiff: absYDiff });
                }
            }
        }
    }

    // pullHandleTouchStart = (e) => {
    //     // console.log('touchStart', e);
    //     // Determine if the event is a touch event or a mouse event
    //     const isTouchEvent = e.type.includes('touch');
    //     const point = isTouchEvent ? e.touches[0] : e;
    //     const element = e.target.closest('.drawer-wrapper');
    //     element.style.transition = '0s';

    //     this.setState({
    //         isDragging: true,
    //         startX: point.clientX,
    //         startY: point.clientY,
    //     });
    // }

    // pullHandleTouchEnd = (e) => {
    //     const { yDiff, pulledDown } = this.state;
    //     // console.log('touchEnd', e);
    //     const element = e.target.closest('.drawer-wrapper');
    //     element.style.transition = '0.3s';

    //     this.setState({
    //         isDragging: false,
    //         startY: undefined,
    //         startX: undefined,
    //         yDiff: 0,
    //         // pulledDown: yDiff + pulledDown,
    //     });
    // }

    // pullHandleTouchMove = (e) => {
    //     e.preventDefault(); // Prevent default action to avoid text selection, page scrolling, etc.
    //     e.stopPropagation();

    //     const { isDragging } = this.state;
    //     if (!isDragging) {
    //         return;
    //     }

    //     const element = e.target.closest('.pull-handle');
    //     if (!element) {
    //         return;
    //     }

    //     // Determine if the event is a touch event or a mouse event
    //     const isTouchEvent = e.type.includes('touch');
    //     const point = isTouchEvent ? e.touches[0] : e;

    //     const { startY, startX, height } = this.state;
    //     const xUp = point.clientX;
    //     const yUp = point.clientY;

    //     const xDiff = startX - xUp;
    //     const yDiff = startY - yUp;
    //     const absXDiff = Math.abs(xDiff);
    //     const absYDiff = Math.abs(yDiff);

    //     if (absXDiff > absYDiff) {
    //         // Horizontal movement
    //         // console.log('Horizontal swipe', { startX, startY, xDiff });
    //     } else if (yDiff > 0) {
    //         // Vertical up movement
    //         console.log('Up swipe', { startX, startY, yDiff });
    //         this.setState({ pulledDown: yDiff });
    //     } else {
    //         // Vertical down movement
    //         console.log('Down swipe', { startY, startX, yDiff });
    //         this.setState({ pulledDown: yDiff });
    //     }
    // }

    componentDidMount() {
        this.animateHeight(this.props);
        window.addEventListener('keydown', this.handleKeyDown);
	}

    componentWillUnmount() {
        // remove event listener
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    render() {
        const { height, yDiff, pulledDown } = this.state;
        const { appState, userStore } = this.props.stores;
        const { currentCustomer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        const {
            children,
            classNames = 'fixed-bottom',
            width = '100vw',
            // height = '75vh',
            editFunction,
            drawer = 1,
            borderRadius = '20px 20px 0 0 !important',
            title,
            id,
            showCloseButton = true,
            showBackButton = false,
            backButtonText = 'Back',
            showPullHandle = false,
        } = this.props;

        let isAndroid = false;
		if (/Android/i.test(navigator.userAgent)) {
			isAndroid = true;
        }
        let calcHeightPx = (drawer -  1) * 20;
        if (isAndroid) {
            calcHeightPx += 30;
        }
        const calcHeight = `${calcHeightPx}px`;

        return (<>
            <div
                class='w-100 h-100 fixed-bottom'
                style={`
                    height: calc(100vh) !important;
                    top: 0;
                    left: 0;
                    overflow-x: auto;
                    overflow-y: auto;
                    transition: .3s;
                    overscroll-behavior: contain;
                    z-index: ${DRAWER_Z_INDEX + drawer * 10};
                    background-color: rgba(0,0,0,.6);
                `}
                onClick={this.onCloseClick}
                onTouchmove={localUtil.captureEvents}
                onScroll={localUtil.captureEvents}
                onWheel={localUtil.captureEvents}
            />
            <div
                class={`drawer-wrapper ${classNames} ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} bg-light ${title ? 'pt-5' : ''}`}
                style={`
                    bottom: 0;
                    left: 0;
                    width: ${width};
                    height: calc(${height} - ${calcHeight} ${yDiff > 0 ? ` - ${yDiff}px` : ''} ${pulledDown !== 0 ? ` + ${pulledDown}px` : ''});
                    max-height: 100vh;
                    min-height: 100px;
                    transition: .3s;
                    overflow: hidden;
                    z-index: ${DRAWER_Z_INDEX + drawer * 10 + 1};
                    border-radius: ${borderRadius};
                    border-top-width: 1px !important;
                    ${currentCustomer?.backgroundColor ? `background-color: ${currentCustomer.backgroundColor} !important;` : ''}
                `}
                onTouchmove={localUtil.captureEvents}
                onScroll={localUtil.captureEvents}
                onWheel={localUtil.captureEvents}
            >
                {showPullHandle && <>
                    <div
                        class='position-absolute w-100 d-flex flex-row justify-content-center pull-handle'
                        style={`
                            top: 0;
                            left: 0;
                            z-index: ${DRAWER_Z_INDEX + drawer * 10 + 2};
                            font-size: 2.0rem;
                            opacity: 0.3;
                        `}
                        onTouchstart={this.pullHandleTouchStart}
                        onTouchend={this.pullHandleTouchEnd}
                        onTouchmove={this.pullHandleTouchMove}
                        // onMouseDown={this.pullHandleTouchStart}
                        // onMouseUp={this.pullHandleTouchEnd}
                        // onMouseOut={this.pullHandleTouchEnd}
                        // onMouseMove={this.pullHandleTouchMove}
                    >
                        <i class='fa-solid fa-grip-lines' />
                    </div>
                </>}

                {showBackButton && <button
                    type='button'
                    class={`btn btn-link position-absolute appHeader ${linkTextClass}`}
                    style={`
                        top: 2px;
                        left: 5px;
                        z-index: ${DRAWER_Z_INDEX + drawer * 10 + 3};
                        border-radius: 50%;
                        ${linkTextStyle}
                    `}
                    onClick={this.onCloseClick}
                >
                    <i class={`fa-regular fa-arrow-left`} /> {backButtonText}
                </button>}
                {title && <div class='position-absolute w-100 bg-light pt-3 pb-1'
                    style={`
                        top: 0px;
                        left: 0px;
                        z-index: ${DRAWER_Z_INDEX + drawer * 10 + 3};
                    `}
                >
                    <h4 class='text-center'>{title}</h4>
                </div>}
                {editFunction && <div
                    class='position-absolute w-100'
                    style={`
                        top: 19px;
                        left: ${showBackButton ? '85px' : '20px'};
                        z-index: ${DRAWER_Z_INDEX + drawer * 10 + 3};
                    `}
                >
                    <button
                        type='button'
                        // class='btn btn-link position-absolute appHeader text-secondary'
                        class='btn btn-sm btn-outline-primary rounded-pill'

                        data-id={id}
                        data-title={title}
                        onClick={editFunction}
                    >
                        <i class='fa-regular fa-pen' /> <Text id='input.edit'>Edit</Text>
                    </button>
                </div>}
                {showCloseButton && <button
                    type='button'
                    class='btn btn-sm btn-secondary float-right position-absolute'
                    style={`
                        top: 19px;
                        right: 16px;
                        width: 32px;
                        height: 32px;
                        z-index: ${DRAWER_Z_INDEX + drawer * 10 + 3};
                        border-radius: 50%;
                        filter: opacity(0.5);

                    `}
                    onClick={this.onCloseClick}
                >
                    <i class='fas fa-times' />
                </button>}
                <div
                    class={`h-100 position-relative overflow-auto bg-light drawer-content`}
                    // class={`h-100 position-relative overflow-auto bg-light`}
                    onTouchstart={this.touchStart}
                    onTouchend={this.touchEnd}
                    onTouchmove={this.touchMove}
                    onScroll={this.onScroll}
                    style={`
                        ${currentCustomer?.backgroundColor ? `background-color: ${currentCustomer.backgroundColor} !important;` : ''}
                    `}
                >
                    {children}
                    {isAdmin && <>
                        <div class='fixed-bottom bg-info text-center w-25' style='bottom: 0; right: 0; line-height: 0.9em; font-size: 0.7em;'>
                            drawer: {drawer}
                        </div>
                    </>}
                </div>
            </div>
        </>);
    }
}

export default Drawer;
